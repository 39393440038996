import { IGood, ISelectedData, Uuid } from "@lib";
import { EventHandler } from "@core/components/alt-ui";
import { Control } from "@core/components/alt-ui/controls";

export class SearchGoodsSelectItemEventArgs {
    item!: IGood | null;
}

export class SearchGoods extends Control {
    public key = Uuid.new();
    public label = "";
    public placeholder = "";
    public selectSingle = false;
    public searchFunc: ((search: string) => Promise<ISelectedData<IGood>>) | null = null;

    public update(): void {
        this.key = Uuid.new();
    }

    private _selectItemHandlers = new Set<EventHandler<SearchGoodsSelectItemEventArgs>>();
    public addSelectItemHandler(handler: EventHandler<SearchGoodsSelectItemEventArgs>): void {
        this._selectItemHandlers.add(handler);
    }
    public removeSelectItemHandler(handler: EventHandler<SearchGoodsSelectItemEventArgs>): void {
        this._selectItemHandlers.delete(handler);
    }
    public notifySelectItemHandler(item: IGood): void {
        const args: SearchGoodsSelectItemEventArgs = { item };

        for (const handler of this._selectItemHandlers) {
            handler(this, args);
        }
    }

    public getComponentName(): string {
        return "SearchGoodsComponent";
    }
}
