import {
    IStore,
    IGoodWriteOffCreateDto,
    IGoodCategory,
    IGoodWriteOff,
    IGood,
    IGoodWriteOffInfo,
    IReadQuery,
} from "@lib";
import { Control, Icon, Label, NumberBox, Select, TextArea } from "@core/components/alt-ui/controls";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Footer } from "@core/controls/footer.control";
import { Table, TableColumn, TableFooter } from "@core/components/alt-ui/table";
import { SearchGoods } from "@/core/alt-forms/components/search-goods/search-goods";
import { GoodUseCase } from "@core/usecases/store/good.usecase";
import { Uuid } from "@/utils/uuid";
import { AltMessage } from "@/utils/plugins/alt-message";
import { AltToast } from "@/utils/plugins/alt-toast";
import { moneyFormat } from "@/filters/money";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GoodWriteOffModalContext {
    userId: string;
    stores: IStore[];
    companyId: string;
    storeId: string;
    categories: IGoodCategory[];
    goodWriteOff?: IGoodWriteOff;
    message: AltMessage;
    toast: AltToast;
}

export type GoodWriteOffTableItem = {
    good: IGood;
    quantity: number;
};

export class GoodWriteOffModal extends Modal2<GoodWriteOffModalContext> {
    private cbStore!: Select<IStore>;

    private ctSearchGoods!: SearchGoods;
    private tblGoodsTable!: Table<GoodWriteOffTableItem>;

    private taComment!: TextArea;

    private ftFooter!: Footer;

    private context!: GoodWriteOffModalContext;
    public onCreate: ((dto: IGoodWriteOffCreateDto) => Promise<any | null>) | null = null;

    private GoodUseCase = new GoodUseCase();

    public constructor(id: string) {
        super(id, "");

        this.scrollable = false;
        this.initializeControls();
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public show(context: GoodWriteOffModalContext): Promise<void> {
        this.context = context;
        this.title = "Списание";

        this.initializeControls();

        if (this.context.goodWriteOff) {
            this.populateControls(this.context.goodWriteOff);
        }

        return super.show();
    }

    private getControlId(controlId: string): string {
        return `good-write-off-modal.${controlId}`;
    }

    protected initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.tblGoodsTable = new Table<GoodWriteOffTableItem>();
        this.taComment = new TextArea();

        this.cbStore.id = "good.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => o.id === this.context?.storeId) ??
            (this.cbStore.items.length > 0 ? this.cbStore.items[0] : null);

        //

        this.ctSearchGoods = new SearchGoods();
        this.ctSearchGoods.id = this.getControlId("search-goods");
        this.ctSearchGoods.label = "Введите название товара";
        this.ctSearchGoods.selectSingle = true;
        this.ctSearchGoods.searchFunc = search => {
            const query: IReadQuery = {
                search,
                limit: 20,
                offset: 0,
                filter: {
                    quantity: { gt: 0 },
                    store: { in: [this.cbStore.selectedItem?.id ?? this.context.storeId] },
                },
            };

            return this.GoodUseCase.select(this.context.companyId, query);
        };
        this.ctSearchGoods.addSelectItemHandler((s, e) => {
            if (!e.item) {
                return;
            }

            const good = e.item;
            const existed = this.tblGoodsTable.items.find(i => i.good.id === good.id);

            if (existed) {
                if (existed.quantity >= good.info.quantity) {
                    this.context.toast.warning("Нельзя списать больше, чем есть на складе");
                } else {
                    existed.quantity += 1;
                }
            } else {
                this.tblGoodsTable.items.push({
                    good,
                    quantity: 1,
                });
            }
        });

        //

        this.tblGoodsTable.id = this.getControlId("goodsTable");
        this.tblGoodsTable.class = "mt-2";
        this.tblGoodsTable.columns = this.getTableColumns(false);
        this.tblGoodsTable.footer = this.getTableFooter();

        //

        this.taComment.id = this.getControlId("comment");
        this.taComment.label = "Комментарий";

        this.ctSearchGoods.visible = true;
        this.cbStore.disabled = false;
        this.taComment.disabled = false;

        this.ftFooter = new Footer({
            okText: this.context?.goodWriteOff ? "" : "Списать",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(goodWriteOff: IGoodWriteOff): void {
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => {
                return o.id === goodWriteOff.store;
            }) ?? null;
        this.cbStore.disabled = true;

        this.ctSearchGoods.visible = false;

        this.tblGoodsTable.items = goodWriteOff.goods.map(g => {
            return {
                good: g.goodRef as IGood,
                quantity: g.writedOffQuantity,
            };
        });

        this.tblGoodsTable.columns = this.getTableColumns(true);

        this.taComment.text = goodWriteOff.comment ?? "";
        this.taComment.disabled = true;
    }

    private getTableColumns(readonly: boolean): TableColumn<GoodWriteOffTableItem>[] {
        const columns: TableColumn<GoodWriteOffTableItem>[] = [
            {
                title: "Наименование",
                width: 50,
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => item.good.info.name,
            },
            {
                title: "На складе",
                classHeader: "text-xs",
                width: 20,
                cell: item => item.good?.info.quantity.toString(),
            },
            {
                title: "Количество",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    if (readonly) {
                        const lbl = new Label();
                        lbl.id = Uuid.new();
                        lbl.class = "text-base";
                        lbl.text = item.quantity.toString();
                        return lbl;
                    }

                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.max = item.good.info.quantity;
                    number.value = item.quantity;
                    number.disabled = readonly;
                    number.addValueChangedHandler((s, e) => {
                        item.quantity = e.value ?? 0;
                    });

                    return number;
                },
            },
        ];

        if (!readonly) {
            columns.unshift({
                width: 1,
                cell: (item, index) => {
                    const iconDelete = new Icon();
                    iconDelete.icon = "Trash2Icon";
                    iconDelete.class = "cursor-pointer hover:text-danger";
                    iconDelete.addClickHandler(async (s, e) => {
                        const confirm = await this.context.message.confirm(
                            `Вы уверены, что хотите удалить товар "${item.good.info.name}"?`,
                            "Удаление товара",
                            { acceptText: "Удалить" },
                        );

                        if (confirm) {
                            this.tblGoodsTable.items = this.tblGoodsTable.items.filter((_, i) => i !== index);
                        }
                    });
                    return iconDelete;
                },
            });
        }

        return columns;
    }

    private getTableFooter(): TableFooter<GoodWriteOffTableItem>[] {
        return [
            {
                title: "Сумма:",
                cell: items => {
                    const locale = this.cbStore.selectedItem?.info.locale;
                    const value = items.reduce((sum, item) => (sum += item.good.info.cost * item.quantity), 0);
                    return moneyFormat(value, { locale });
                },
            },
        ];
    }

    public get controls(): Control[] {
        return [this.cbStore, this.ctSearchGoods, this.tblGoodsTable, this.taComment];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.tblGoodsTable.items.length) {
            return;
        }

        const result = await this.create();

        if (result) {
            this.hide(result);
        }
    }

    private async create(): Promise<any> {
        if (!this.onCreate) {
            return null;
        }

        if (!this.cbStore.selectedItem) {
            return null;
        }

        const amount = this.tblGoodsTable.items.reduce((sum, item) => (sum += item.good.info.cost * item.quantity), 0);

        const goods = this.tblGoodsTable.items.map(item => {
            const good: IGoodWriteOffInfo = {
                id: item.good.id,
                cost: item.good.info.cost,
                quantity: item.good.info.quantity,
                writedOffQuantity: item.quantity,
            };

            return good;
        });

        const dto: IGoodWriteOffCreateDto = {
            goods: goods as IGoodWriteOffInfo[],
            storeId: this.cbStore.selectedItem.id,
            employeeId: this.context.userId,
            amount,
            comment: this.taComment.text,
        };

        return await this.onCreate(dto);
    }
}
