import { Component, Vue } from "vue-property-decorator";
import {
    IGoodWriteOff,
    IGoodWriteOffCreateDto,
    IGoodWriteOffUpdateDto,
    ISettingsTableUpdateDto,
    ITable,
    PermissionType,
    ITableFilter,
    ITableSort,
    TableType,
    PermissionRight,
    IGoodCategory,
    IUser,
    ICompany,
    IStore,
    IReadQuery,
} from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import AltStatisticCard from "@/core/components/alt-statistic-card";
import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import { getDefaultTableColumns } from "./good-write-offs-defaults";
import GoodWriteOffsToolbar from "./good-write-offs-toolbar.vue";
import { convertFilterBack } from "./good-write-offs-filter-defaults";
import { GoodWriteOffModal } from "./good-write-off.modal";

@Component({
    name: "good-write-offs",
    components: {
        AltTable,
        GoodWriteOffsToolbar,
        Modal2Component,
        //AltStatisticCard,
    },
})
export default class GoodWriteOffs extends Vue {
    private GoodWriteOffUseCase = this.$alt.system.usecase.createGoodWriteOffUseCase();
    private GoodCategoryUseCase = this.$alt.system.usecase.createGoodCategoryUseCase();
    private SettingsTableUseCase = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private writeOffs: IGoodWriteOff[] = [];
    private writeOffsTotal: number = 0;
    // private writeOffTotalAmount = 0;
    private stores: IStore[] = [];
    private categories: IGoodCategory[] = [];

    private goodWriteOffModal: GoodWriteOffModal;

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
    };

    constructor() {
        super();

        this.goodWriteOffModal = new GoodWriteOffModal("good-write-off-modal");
        this.goodWriteOffModal.onCreate = this.create.bind(this);

        this.goodWriteOffModal.addVisibleChangedHandler(handler => {
            const isVisible = handler._visible;
            const context = handler.context;

            if (isVisible) {
                if (context.goodWriteOff) {
                    this.$router.push({ query: { id: context.goodWriteOff.id } }).catch(() => {});
                } else {
                    this.$router.push({ query: { new: "" } }).catch(() => {});
                }
            } else {
                this.$router.push({ query: undefined }).catch(() => {});
            }
        });
    }

    private get tableType(): TableType {
        return TableType.GoodWriteOff;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get writeOffsData(): IGoodWriteOff[] {
        return this.writeOffs ? this.writeOffs : [];
    }

    private get writeOffsDataTotal(): number {
        return this.writeOffsTotal ? this.writeOffsTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.stores;

        return {
            get read(): boolean {
                return true;
            },
            get update(): Function {
                return (goodWriteOff: IGoodWriteOff): boolean => {
                    return secure.check(PermissionType.Stores, goodWriteOff.store, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (goodWriteOff: IGoodWriteOff): boolean => {
                    return secure.check(PermissionType.Stores, goodWriteOff.store, PermissionRight.GoodsDelete);
                };
            },
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings?.columns ?? []);
    }

    private get limit(): number {
        return this.table.settings?.limit ?? TableApi.DefaultLimit;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings?.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings?.filter ?? [];
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings()]);

        this.categories = await this.GoodCategoryUseCase.selectAll(this.company.id);

        const filter = {
            store: this.stores.map(e => e.id),
        };

        if (!this.table.settings) {
            this.table.settings = {};
        }

        this.table.settings.filter = convertFilterBack(filter);

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "good-write-offs.header-title";
        hdrTitle.html = '<h2 class="m-0">Списания</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "good-write-offs.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                this.showModalCreate();
                return;
            }

            if (this.$route.query.id) {
                const writeOffId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const writeOff = await this.getGoodWriteOff(writeOffId);
                this.showModalUpdate(writeOff);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Списание не найдено.");
        }
    }

    private async updateFilter(): Promise<void> {
        const dto: ISettingsTableUpdateDto = { filter: this.filter };
        await this.SettingsTableUseCase.update(this.company.id, this.user.id, TableType.Good, dto);
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTableUseCase.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings?.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTableUseCase.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;

            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async showModalCreate(): Promise<void> {
        await this.goodWriteOffModal.show({
            userId: this.user.id,
            companyId: this.company.id,
            stores: this.stores,
            storeId: this.stores.length > 0 ? this.stores[0].id : "",
            categories: this.categories,
            message: this.$alt.message,
            toast: this.$alt.toast,
        });
    }

    private async showModalUpdate(goodWriteOff: IGoodWriteOff): Promise<void> {
        await this.goodWriteOffModal.show({
            userId: this.user.id,
            companyId: this.company.id,
            stores: this.stores,
            storeId: this.stores.length > 0 ? this.stores[0].id : "",
            categories: this.categories,
            goodWriteOff,
            message: this.$alt.message,
            toast: this.$alt.toast,
        });
    }

    private async confirmDelete(goodWriteOff: IGoodWriteOff): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить списание: #${goodWriteOff.number}?`,
            "Удаление списания",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(goodWriteOff);
        }
    }

    // private async confirmDeleteMany(writeOffs: IGoodWriteOff[]): Promise<void> {
    //     const result = await this.$alt.message.confirm(
    //         `Вы уверены, что хотите удалить ${writeOffs.length} списаний?`,
    //         "Удаление списаний",
    //         { acceptText: "Удалить" },
    //     );

    //     if (result) {
    //         await this.deleteMany(writeOffs);
    //     }
    // }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.GoodWriteOffUseCase.select(this.company.id, query);

            this.writeOffs = result.data;
            this.writeOffsTotal = result.total;
            // this.writeOffTotalAmount = this.writeOffs.reduce((sum, wo) => sum + wo.amount, 0);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить списания:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.writeOffs = [];
            this.writeOffsTotal = 0;
        }
    }

    private async getGoodWriteOff(id: string): Promise<any> {
        try {
            const goodWriteOff = await this.GoodWriteOffUseCase.get(this.company.id, id);
            if (!goodWriteOff) {
                throw new Error("Списание не найдено.");
            }

            return goodWriteOff;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные списания:\n${e.message}`);
        }
    }

    private async create(dto: IGoodWriteOffCreateDto): Promise<boolean> {
        try {
            this.$alt.loader.show();

            await this.GoodWriteOffUseCase.create(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Списание успешно создано.");
            this.$alt.loader.hide();
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            this.$alt.loader.hide();
            return false;
        }
    }

    private async updateGoodWriteOff(goodWriteOff: IGoodWriteOff, dto: IGoodWriteOffUpdateDto): Promise<void> {
        try {
            this.$alt.loader.show();

            await this.GoodWriteOffUseCase.update(this.company.id, goodWriteOff.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Списание успешно изменено.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(goodWriteOff: IGoodWriteOff): Promise<void> {
        try {
            this.$alt.loader.show();

            await this.GoodWriteOffUseCase.delete(this.company.id, goodWriteOff.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Списание успешно удалено.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    // private async deleteMany(writeOffs: IGoodWriteOff[]): Promise<void> {
    //     try {
    //         this.$alt.loader.show();

    //         const dto: IClientDeleteManyDto = {
    //             ids: writeOffs.map(e => e.id),
    //         };

    //         await this.GoodWriteOffUseCase.deleteMany(this.company.id, dto);
    //         await this.selectData(this.skip, this.limit, this.sort, this.filter);
    //         this.$alt.toast.success("Клиенты успешно удалены.");
    //     } catch (e: any) {
    //         this.$alt.toast.error(e.message);
    //     } finally {
    //         this.$alt.loader.hide();
    //     }
    // }
}
